import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Header = ({ isRootPath, title }) => {
    let header;

  if (isRootPath) {
    header = (
        <div className="header__inner">
            <Link to="/" className="header__link">
                <h1 className="header__title">
                    {title}
                </h1>
            </Link>
            <Link to="/about" className="header__link">
                <span className="header__about">About</span>
            </Link>
        </div>
    )
} else {
    header = (
        <div className="header__inner">
            <Link to="/" className="header__link">
                <h1 className="header__title">
                    {"<- Back"}
                </h1>
            </Link>
            <Link to="/about" className="header__link">
                <span className="header__about">About</span>
            </Link>
        </div>
    )
  }

  return (
      <header className="header">{header}</header>
  )
}

Header.propTypes = {
    isRootPath: PropTypes.bool,
    title: PropTypes.string,
}

Header.defaultProps = {
    isRootPath: false,
    isRootPath: ``,
}

export default Header
